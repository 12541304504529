<template>
    <div class="calls">
        <div class="calls__col calls__col--left">
            <div class="timing">
                <div class="timing__table-wrapper relative" :style="`min-height: ${loading ? '300px' : 'initial'};`">
                    <Spinner v-if="loading" style="position: absolute;" />
                    <FullCalendar ref="fullCalendar" :options="calendarOptions" id='report-shedule' class="shedule-report" style="height: calc(100vh - 240px);" v-if="!loading" />
                </div>
            </div>
        </div>
        <div class="calls__col calls__col--right">
            <div class="headline headline--sm">
                <h2 class="headline__title">{{ $translate('scheduled-calls') }}</h2>
                <p class="headline__text">{{ $translate('here-you-can-find-calls-that-you-scheduled') }}</p>
            </div>

            <div
                v-if="!calls.length"
                style="padding: 30px;display: flex;flex-direction: column;align-items: center;"
            >
                {{ $translate('scheduled-a-call-list-is-empty') }}
                <button type="button" class="btn btn-w-shadow btn-standart-2" v-if="!calls.length" style="margin-top: 10px;" @click="$emit('openScheduleACall')">
                    <inline-svg :src="require('@/assets/img/ico-call.svg')" class="pointer-events-none" />
                    <span>{{ $translate('schedule-a-call') }}</span>
                </button>
            </div>

            <ul class="calls__list">
                <li class="calls__item" v-for="item in calls" :key="item.id">
                    <div class="data-row">
                        <span class="data-row__text calendar_to_link" @click="goCalendarToDate(item.date)">
                            {{ item.date }}, {{ `${item.time.split(', ')[0]} ${item.time.split(', ')[1]}` }}
                        </span>
                        <div class="data-row__controls">
                            <button type="button" class="btn btn-icon" @click="editCall(item)">
                                <inline-svg :src="require('@/assets/img/ico-gear.svg')" class="pointer-events-none" />
                            </button>
                            <button type="button" class="btn btn-icon red-color" @click="removeCall(item)">
                                <inline-svg :src="require('@/assets/img/trash-delete.svg')" class="pointer-events-none" />
                            </button>
                        </div>
                    </div>
                </li>
                <!-- <li class="calls__item">
                    <div class="data-row">
                        <span class="data-row__text">13 Sep 2021, 09:25 PM</span>
                        <div class="data-row__controls">
                            <button type="button" class="btn btn-icon">
                                <inline-svg :src="require('@/assets/img/ico-gear.svg')" class="pointer-events-none" />
                            </button>
                            <button type="button" class="btn btn-icon red-color">
                                <inline-svg :src="require('@/assets/img/trash-delete.svg')" class="pointer-events-none" />
                            </button>
                        </div>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</template>

<script>
import '@fullcalendar/core/vdom';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction';
import Base from "@/components/base";

import { API } from "aws-amplify";
import { listUpcomingCalls } from '@/graphql/queries';
import { deleteUpcomingCalls } from '@/graphql/mutations';

export default {
    name: 'UpcomingCalls',
    components: {
		FullCalendar,
		...Base,
	},
    data() {
		return {
			loading: true,
			calendarOptions: {
				plugins: [ dayGridPlugin, interactionPlugin, timeGridPlugin ],
				initialView: 'dayGridMonth',
				headerToolbar: {
					left: 'title,prev,next',
					center: 'today',
					right: 'timeGridDay,timeGridWeek,dayGridMonth',
				},
				// contentHeight: 'auto',
				height: 'parent',
                // [
                //     {
                //         "id":"db34bf4f-ac48-4f20-b6f9-3f55e3180f05",
                //         "title":"Flowers seeding",
                //         "start":"2022-04-01T08:00:00.212Z",
                //         "allDay":false
                //     },
                // ]
				events: [],
				eventClick: function(info) {
					window.CalendarActionsView.singleActionChange(info.event.id)
				},
				defaultDate: new Date(),
			},
            calls: [],
		}
	},
    computed: {
		userData() {
			return this.$store.state.userData || {}
		},
	},
    methods: {
        async getCalls(){
            this.loading = true;
            this.calendarOptions.events = [];

            const filter = {
                user: {
                    eq: this.userData.id
                }
            };

            this.calls = await API.graphql({
                query: listUpcomingCalls,
                variables: {
                    filter
                },
            }).then((res) => res.data.listUpcomingCalls.items);

            window.CalendarActionsView = this;
            this.calendarOptions.events = this.calls.map(item => {
                let time = item.time.split(', ')[0];
                if(time.split(':')[0].length === 1) time = '0'+time;

                return {
                    "id": item.id,
                    "title": `${ item.time.split(', ')[0] } ${ item.time.split(', ')[1] }`,
                    "start": `${ item.date.split('.')[2] }-${ item.date.split('.')[1] }-${ item.date.split('.')[0] }T${ time }:00.000Z`,
                    "allDay": false
                }
            });

            setTimeout(() => {
                this.loading = false;
            }, 2000);
        },
        singleActionChange(id){
            console.log('singleActionChange:', id);
		},
        goCalendarToDate(date){
            let localeData = new Date(date.split('.')[2], date.split('.')[1]-1, date.split('.')[0]).toLocaleDateString('en-GB').split('/');

            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.gotoDate(`${localeData[2]}-${localeData[1]}-${localeData[0]}`);
        },
        editCall(call){
            this.$emit('edit', call);
        },
        async removeCall(call){
            const options = { title: "Remove this call?", size: "sm", okLabel: "Remove", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await API.graphql({
                        query: deleteUpcomingCalls,
                        variables: {
                            input: {
                                id: call.id,
                            }
                        }
                    });

                    await this.getCalls();
                }
            });
        },
    },
    async mounted(){
        await this.getCalls();
    }
}
</script>

<style>
.calendar_to_link {
    cursor: pointer;
    transition: 0.15s all ease-in-out;
}
.calendar_to_link:hover {
    opacity: 0.7;
}
</style>