<template>
<div class="wrapper_main">
	<main class="content">
		<div class="content__header border-none">
			<div class="content__header-left no-wrap flex-fill">
				<h1 class="heading-page-h1">{{ $translate('support') }}</h1>
				<form action="#" class="content__header-search" v-if="tabActive === 'Support Tickets'">
					<input type="search" class="input bg-left search-icon" placeholder="Search" v-model="search">
				</form>
			</div>
			<div class="content__header-right">
				<!-- <button type="button" class="btn btn-w-shadow btn-standart-2" @click="scheduleACallPopupShow = true">
                    <inline-svg :src="require('@/assets/img/ico-call.svg')" class="pointer-events-none" />
					<span>Schedule a Call</span>
				</button> -->
				<button type="button" class="btn btn-primary btn-standart" @click="raiseTicketPopupShow = true">
                    <inline-svg :src="require('@/assets/img/add-iot-icon.svg')" class="pointer-events-none" />
					<span>{{ $translate('contact-us') }}</span>
				</button>
			</div>
		</div>
		<div class="tabs-wrapper">
			<ul class="tabs">
				<li class="tabs__item" v-for="item in tabs" :key="item.title">
					<a 
                        class="nav-tab" :class="{ 'active':tabActive === item.title }"
                        @click="changeTab(item)"
                    >
                        {{ item.title }}
                    </a>
				</li>
			</ul>
		</div>
		<div class="tab-content">
			<div id="tickets" class="tab-content__item active" v-if="tabActive === 'Support Tickets' && false">
                <TableAdvanced 
                    v-model="sortCol" :rows="filteredRows" :columns="columns" 
                    :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" 
                    @toggleAllCheckedInputs="toggleAllCheckedInputs"
                    class="action_text_align_right"
                >
                    <template v-slot:row="slotData">
                        <td>
                            <label class="module__check">
                                <input type="checkbox" name="category" v-model="checkedInputs[slotData.r.id]" />
                                <span class="check"></span>
                                <span class="text">{{ `${new Date(+slotData.r.date).toISOString().split('T')[0]}, ${new Date(+slotData.r.date).toISOString().split('T')[1].slice(0, 5)}` }}</span>
                            </label>
                        </td>
                        <td>
                            <span class="text">{{ slotData.r.status || '-' }}</span>
                        </td>
                        <td>
                            <span class="text">{{ slotData.r.category || '-' }}</span>
                        </td>
                        <td>
                            <span class="text">{{ slotData.r.subject || '-' }}</span>
                        </td>
                        <td>
                            <span class="text" style="display: block;overflow: hidden;max-width: 340px;text-overflow: ellipsis;">
                                {{ slotData.r.description || '-' }}
                            </span>
                        </td>
                        <td class="text-end border-action">
                            <div class="table-actions flex items-center justify-end">
                                <button type="button" class="btn btn-icon" @click="editTicket(slotData.r)">
                                    <inline-svg :src="require('@/assets/img/ico-gear.svg')" class="pointer-events-none" style="min-width: 18px;min-height: 18px;" />
                                </button>
                                <button type="button" class="btn btn-icon red-color" @click="removeTicket(slotData.r)">
                                    <inline-svg :src="require('@/assets/img/trash-delete.svg')" class="pointer-events-none" />
                                </button>
                            </div>
                        </td>
                    </template>	
                </TableAdvanced>

                <div
                    v-if="!supportTickets.length"
                    style="padding: 30px;display: flex;flex-direction: column;align-items: center;"
                >
                    {{ $translate('support-tickets-list-is-empty') }}
                    <button type="button" class="btn btn-primary btn-standart" @click="raiseTicketPopupShow = true" style="margin-top: 10px;">
                        <inline-svg :src="require('@/assets/img/add-iot-icon.svg')" class="pointer-events-none" />
                        <span>{{ $translate('raise-a-ticket') }}</span>
                    </button>
                </div>

                <Pagination v-model="filteredRows" :items="rawSource" />
			</div>
			<div id="faq" class="tab-content__item active" v-if="tabActive === 'FAQ'">
				<div class="faq">
					<div class="headline headline--sm">
						<h2 class="headline__title">{{ $translate('frequently-asked-questions') }}</h2>
						<p class="headline__text">{{ $translate('you-can-find-answers-for-your-questions-here') }}</p>
					</div>
					<div class="accordion">
                        <template v-for="(item, index) in faq">                   
                            <div class="accordion__item" :key="index" v-if="faqAccordeons.length === faq.length">
                                <h3 class="accordion__header">
                                    <a class="accordion__button" :class="{ 'collapsed':!faqAccordeons[index] }" @click="$set(faqAccordeons, index, !faqAccordeons[index])">
                                        {{ item.question }}
                                    </a>
                                </h3>
                                <div class="accordion__collapse collapse" :class="{ 'show':faqAccordeons[index] }">
                                    <div class="accordion__body">
                                        <p v-html="item.answer"></p>
                                    </div>
                                </div>
                            </div>
                        </template>
					</div>
				</div>
			</div>
			<div id="calls" class="tab-content__item active" v-if="tabActive === 'Upcoming Calls' && false">
				<UpcomingCalls ref="upcomingCalls" @edit="editCall" @openScheduleACall="scheduleACallPopupShow = true" />
			</div>
            <div id="video_tutorials" class="tab-content__item active" v-if="tabActive === 'Video tutorials'">
                <div class="faq">
                    <div class="headline headline--sm">
                        <h2 class="headline__title">{{ $translate('video-tutorials') }}</h2>
                        <!-- <p class="headline__text">{{ $translate('in-development') }}</p> -->
                    </div>
                    <div>
                        <template v-for="(item, index) in videoTutorials">    
                            <div class="accordion__item" :key="index" v-if="faqVideoAccordeons.length === videoTutorials.length">
                                <h3 class="accordion__header">
                                    <a class="accordion__button" :class="{ 'collapsed':!faqVideoAccordeons[index] }" @click="$set(faqVideoAccordeons, index, !faqVideoAccordeons[index])">
                                        {{ item.title }}
                                    </a>
                                </h3>
                                <div class="accordion__collapse collapse" :class="{ 'show':faqVideoAccordeons[index] }">
                                    <div class="accordion__body">
                                        <video controls style="max-width: 800px;">
                                            <source :src="item.videoPath" type="video/mp4">
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
		</div>
	</main>

    <RaiseTicketPopup
        v-if="raiseTicketPopupShow"
        :editTicketData="editTicketData"
        @create="ticketCreate"
        @close="() => { raiseTicketPopupShow = false; editTicketData = null; }"
    />
    <ScheduleACallPopup
        v-if="scheduleACallPopupShow"
        :editCallData="editCallData"
        @create="callCreated"
        @close="() => { scheduleACallPopupShow = false; editCallData = null; }"
    />

    <div class="popup_wrpr" v-if="ticketCreated">
        <div class="popup__window" @click.self.stop="ticketCreated = false">
            <div class="popup__window-content">
                <div class="popup__window-header mb-30">
                    <h2>Contact Us</h2>
                    <button type="button" class="popup__closer" @click="ticketCreated = false">
                        <inline-svg :src="require('@/assets/img/close-icon.svg')" class="pointer-events-none" />
                    </button>
                </div>
                <div class="popup__window-body">
                    <p>Thank you for contacting us. We will be in touch in the next 48 working hours.</p>
                </div>
                <div class="popup__window-actions">
                    <button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="ticketCreated = false">
                        <span>Cancel</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import RaiseTicketPopup from "@/components/popups/RaiseTicketPopup";
import ScheduleACallPopup from "@/components/popups/ScheduleACallPopup";
import Base from '@/components/base';
import { API } from "aws-amplify";
import { listSupportTickets, listFaqs } from '@/graphql/queries';
import { mixTables } from '@/mixins';
import UpcomingCalls from './UpcomingCalls';
import { deleteSupportTickets, createFaq } from '@/graphql/mutations';

export default {
    name: 'Support',
    components: {
        ...Base,
        RaiseTicketPopup,
        ScheduleACallPopup,
        UpcomingCalls,
    },
    mixins: [mixTables],
    data(){
        return {
            tabs: [
                // {
                //     title: 'Support Tickets',
                //     value: 'support_tickets'
                // },
                {
                    title: 'FAQ',
                    value: 'faq'
                },
                // {
                //     title: 'Upcoming Calls',
                //     value: 'upcoming_calls'
                // }
                {
                    title: 'Video tutorials',
                    value: 'video_tutorials'
                }
            ],
            tabActive: 'FAQ',
            faq: [],
            faqAccordeons: [],
            raiseTicketPopupShow: false,
            scheduleACallPopupShow: false,
            source: 'supportTickets',
			columns: [
                { name: "Date", filter: "date" },
                { name: "Status", filter: "status" },
                { name: "Category", filter: "category" },
                { name: "Subject", filter: "subject" },
                { name: "Description", filter: "description" },
                { name: "Actions", filter: "" },
            ],
            supportTickets: [],
            search: '',
            editTicketData: null,
            editCallData: null,
            ticketCreated: false,
            videoTutorials: [
                {
                    title: 'IoT Dashboard Overview',
                    videoPath: 'videos/Enismaro _ IoT Dashboard Overview.mp4',
                },
                {
                    title: 'Add a Product part 1',
                    videoPath: 'videos/Enismaro _ Products _ Add a Product p.1.mp4',
                },
                {
                    title: 'Add a product part 2 - QR-Code Generation',
                    videoPath: 'videos/Enismaro _ Add a product p.2 _ QR-Code Generation.mp4',
                },
                {
                    title: 'Products - Add A Category',
                    videoPath: 'videos/Enismaro _ Products _ Add A Category.mp4',
                },
                {
                    title: 'Devices - Add IoT Device',
                    videoPath: 'videos/Enismaro _ Devices _ Add IoT Device.mp4',
                },
                {
                    title: 'Devices - Scheduling Devices',
                    videoPath: 'videos/Enismaro _ Devices _ Scheduling Devices.mp4',
                },
                {
                    title: 'Rules - Add a Rule',
                    videoPath: 'videos/Enismaro _ Rules _ Add a Rule.mp4',
                },
                {
                    title: 'Farm Management - Add Field',
                    videoPath: 'videos/Enismaro _ Farm Management _ Add Field.mp4',
                },
                {
                    title: 'Farm Management - Add Paddock',
                    videoPath: 'videos/Enismaro _ Farm Management _ Add Paddock.mp4',
                },
                {
                    title: 'Farm Management - Add Building',
                    videoPath: 'videos/Enismaro _ Farm Management _ Add Building.mp4',
                }
            ],
            faqVideoAccordeons: [], 
        }
    },
    computed: {
		userData() {
			return this.$store.state.userData || {}
		},
        sourceData(){
			return this.supportTickets.filter(item => 
                item.category.toLowerCase().includes(this.search.toLowerCase()) ||
                item.subject.toLowerCase().includes(this.search.toLowerCase()) || 
                item.description.toLowerCase().includes(this.search.toLowerCase())
            );
		},
	},
    methods: {
        changeTab(tabItem){
            this.tabActive = tabItem.title;
            this.$router.push({ query: { tab: tabItem.value }});
        },
        async getSupportTickets(){
            const filter = {
                user: {
                    eq: this.userData.id
                }
            };

            this.supportTickets = await API.graphql({
                query: listSupportTickets,
                variables: {
                    filter
                },
            }).then((res) => res.data.listSupportTickets.items);
        },
        async ticketCreate(){
            this.raiseTicketPopupShow = false;
            await this.getSupportTickets();
            this.ticketCreated = true;
        },
        async editTicket(ticket){
            this.editTicketData = ticket;
            this.raiseTicketPopupShow = true;
        },
        async removeTicket(ticket){
            const options = { title: "Remove this ticket?", size: "sm", okLabel: "Remove", cancelLabel: "Cancel" };
            await this.$dialogs.confirm("", options).then(async (res) => {
                if (res.ok) {
                    await API.graphql({
                        query: deleteSupportTickets,
                        variables: {
                            input: {
                                id: ticket.id,
                            }
                        }
                    });

                    await this.getSupportTickets();
                }
            });
        },
        async callCreated(){
            this.scheduleACallPopupShow = false;
            if(this.tabActive === 'Upcoming Calls') {
                await this.$refs.upcomingCalls.getCalls();
            }
            this.editCallData = null;
        },
        editCall(call){
            this.editCallData = call;
            this.scheduleACallPopupShow = true;
        }
    },
    async created(){
        if(this.$route.query.tab){
            this.tabActive = this.tabs.find(item => item.value === this.$route.query.tab).title;
        }

        for (let index = 0; index < this.videoTutorials.length; index++) {
            this.faqVideoAccordeons.push(false);
        }

        this.faq = await API.graphql({
            query: listFaqs,
        }).then((res) => res.data.listFaqs.items);

        for (let index = 0; index < this.faq.length; index++) {
            if(index === 0){
                this.faqAccordeons.push(true);
            } else {
                this.faqAccordeons.push(false);
            }
        }

        // await this.getSupportTickets();

        // let faqAddArray = [
        //     {
        //         question: `How can I sign up to the Enismaro platform?`,
        //         answer: `To start using the Enismaro platform, you will first have to register to the site using the "Create an Account" section. You will have to enter in the fields a name, a valid email address and a password. By clicking on the "Register" button the registration to the site is completed, an email of "confirmation of successful registration" will be sent to the address you provided, and you can start using the platform.`,
        //     },
        //     {
        //         question: `How can I add a Device?`,
        //         answer: `You are required to click on the Add IOT Device button to add a new device. A popup message will be shown where you can enter information about your device, such as category, name, date of manufacture and date of Purchase. You will also have to enter the information about the unique identifier of their sensor, which is listed on the hardware packaging: DevEui, AppKey, AppEui. Once the information is filled in, through the Add Device button a new device will be added to the IOT dashboard.`,
        //     },
        //     {
        //         question: `How can I search for a device?`,
        //         answer: `You can search for your devices within the dashboard using the "Search" search bar in the IOT dashboard. <br>
        //         You will be able to filter your search by Name , Category , Group.`,
        //     },
        //     {
        //         question: `How do I schedule devices?`,
        //         answer: `To schedule a device, you will first have to get to the Device function from the menu and click on the device you would like to schedule. <br>
        //         Through the "Schedule" feature you can schedule for each IOT device associated with the platform a time period within which you will receive information and alerts as generated by monitoring for each device. You can set a defined activation period: weekly, monthly, single day or use the mass scheduling function: select multiple devices (individually, by category or by group) and set the predefined activation period (e.g. only at night)`,
        //     },
        //     {
        //         question: `How can I add and set a rule?`,
        //         answer: `In the "Rules" tab that can be selected from the menu you can set or modify the rules for each of the IOT devices connected to the platform. To detail: <br>
        //         By clicking on the "Add Rule" button the user can add rules and then setting conditions that devices must meet; for example, you can set: <br>
        //         Information collected from the device (e.g. temperature); Dimensional unit (equal to, greater than, less than, etc.); Target value; Time setting (weekly, monthly, single day, etc.) <br>
        //         Alarms; Modify a rule already set ; Activate or deactivate a rule, with the option to set a timing (once only or periodically, e.g. deactivation on weekends); Delete a rule.`,
        //     },
        //     {
        //         question: `How do I create a Product?`,
        //         answer: `To create a product, select the button "Add a product" . A section will be displayed where you can enter all the information regarding the product. <br>
        //         The sections related to the product are: Basic information; Processing information; Production location information; IOT connection; Linked products to a parent product; Farm information linked to the product; QR code information.`,
        //     },
        //     {
        //         question: `How do I link a product to another one created before?`,
        //         answer: `You can create a product linked to a product created before using the "parent product" function. In the product creation section, it is possible to associate the product to a "parent" product and set the type of link between the two products, that can be: <br>
        //         Transformation: An event that stands for an irreversible transformation of an object (Example - Tomatoes in tomato sauce). <br>
        //         Aggregation: An event that stands for the grouping of products (Example - Aggregation of tomatoes to other tomatoes). <br>
        //         Disaggregation: separating products into a smaller group (Example - Disaggregating a pallet of tomatoes into boxes of tomatoes).`,
        //     },
        //     {
        //         question: `How do I create a shipment?`,
        //         answer: `In a section dedicated to Shipments you can create a new shipment by specifying the product or products you want to ship and the destination of the product. <br>
        //         Clicking on the "Create a shipment" button a section is opened; you can add a series of basic information on the Basic Info tab: Shipment ID and Document ID. In the "Select company" tab you can choose the company to which you will send the product/products. Pressing the "Add company" button you can add the company you want, if it is registered in the platform. In the "Products" tab you can select the products you intend to ship. By clicking on the "Add Batch" button, you can add the production batch you want to ship by creating a Batch ID, i.e. an identification number for the batch.`,
        //     },
        //     {
        //         question: `How do I add Contract Conditions to the shipment?`,
        //         answer: `In the "Contract Conditions" tab you can add contract conditions for shipping. Two types of conditions can be added: Smart Conditions and Business Conditions. <br>
        //         The Smart Conditions are an ideal condition that the product must maintain during product shipment and delivery, beyond that if there are anomalies detected by the sensors connected to the product, they will be recorded in blockchain. Business conditions are conditions that are not related to sensors. For example, you can set a condition that the batch be delivered by a specific day/time to the contract counterparty.`,
        //     },
        //     {
        //         question: `How do I view the status of a shipment?`,
        //         answer: `It is possible to visualize the shipments state using the search bar "All statutes" in the Shipments section. Through this search bar you can filter your search according to the shipment you are looking for: Draft, Pending, Approved, Outgoing, Ingoing, Other Consignments, Completed Consignments, Returned Shipments.`,
        //     },
        //     {
        //         question: `How do I approve or reject a shipment?`,
        //         answer: `If you have received a shipment from another user, you will see a new shipment in his "Shipments TAB" with the status of "pending approval". <br>
        //         By viewing the shipment details, you will be able to approve the shipment via the "Approve" button if the shipment has actually been delivered. <br>
        //         This approval can also be done by scanning the QR code on the shipment. You can also reject the shipment with the "Reject" button.`,
        //     },
        //     {
        //         question: `How do I create a QR-Code for a product?`,
        //         answer: `To create a QR-Code for a product you can get to the "Add product" function and click on the TAB "Settings QR code". <br>
        //         You can now select which type of information you would like to embed in the QR code, which can be printed and attached to the product label, for example:  Information about the company ; Information about IOT sensors connected to the product; Traceability information;Information about business and smart conditions established between users. Then click on "Generate QR code" and download the QR code associated with the product.`,
        //     },
        //     {
        //         question: `How do I create a QR-Code for a shipment?`,
        //         answer: `When you create  a shipment and associates products to a shipment batch, you will have to create a QR code and associate it with the whole batch. You  can select which information to link to the QR code at the dispatch stage, in the same way as for the QR code for the product. The QR code can be generated, printed, and attached to the shipping pallet or in an accompanying letter. <br>
        //         This allows the user receiving the shipment to scan the QR code of the shipment and confirm receipt of the shipment (Shipment Receipt functionality).`,
        //     },
        //     {
        //         question: `How do I define the perimeter of my field?`,
        //         answer: `You can define the perimeter of the cultivation fields and fences through the function <br>
        //         "Add Perimeter", in two ways: Definition of the edges, by delimiting the corners of the perimeter of the area you want to identify; Through the pivot function, you can select a central point and expand the area by defining a circle. Once you have selected the area of the cultivation fields/fences, you can define for each single field the type of cultivation (fruit, vegetables, cereals, etc.) and the variety of cultivation. Assign one or more IOT sensors present or connected to the cultivation field/fence.`,
        //     },
        //     {
        //         question: `How do I add livestock? `,
        //         answer: `You can also add animals and livestock to the map of his farm. Using the "Add animals" function, you can indicate the location of the animal or group of animals on the map. <br>
        //         Animals must be placed within the perimeter of a previously created field or structure. You can indicate, among others, the following basic information about the livestock: Type of livestock; Age class; Breed; Birth information; Origin; Number of animals in the group. It is also possible to associate one or more IOT sensors to the herd, for example a collar for tracking and analysing motor behaviour.`,
        //     },
        //     {
        //         question: `How can I add actions to manage my farm?`,
        //         answer: `You can digitise and keep track of all the activities that take place on your farm. <br>
        //         Through the "add actions" function, you can define all the activities that are carried out and create your own digital farm register. For animal actions, you can record all operations carried out such as vaccinations and treatments.For field actions, you can record all operations related to field, such as sowing, fertilisers, pesticides, etc.`,
        //     },
        //     {
        //         question: `How do I open a support ticket or schedule a call?`,
        //         answer: `In the top left corner of the platform you can click on the "Support" button to request assistance on the application from the Enismaro team in the following ways.Clicking on the "Raise a Ticket" button will open a section where you can request assistance via email from Enismaro describing the details of your problem. Clicking on the "Schedule a call" button will open a calendar where you can schedule a call; you will be contacted directly by customer service. Once you have chosen a day and time from those available, you can confirm the appointment by clicking on "Schedule a call"; or on "Cancel" if you do not want to confirm the operation. All tickets will be visible to the user in the Support ticket section.`,
        //     },
        // ];

        // for (let index = 0; index < faqAddArray.length; index++) {
        //     const faqItem = await API.graphql({
		// 		query: createFaq,
		// 		variables: {
        //             input: {
        //                 ...faqAddArray[index]
        //             }
        //         }
		// 	}).then(res => res.data.createFaq)
        //     console.log('faqItem:', faqItem);
        // }
    }
}
</script>