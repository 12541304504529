import {
    devCats,
    devStatus,
    devTypes
} from '@/config';

import {
    checkWarranty,
    grandFilter
} from '@/helpers'


import { API } from "aws-amplify";
import * as graphqlMutations from "@/graphql/mutations";



export const mixProcessing = {
    data() {
        return {
            processing: false,
        }
    },

    methods: {
        process(bool = true) {
            this.processing = bool
        }
    }
}






// propsTableAdvanced:
//     ---for table---
//     v-model="sortCol" 
//     :rows="filteredRows" 
//     :columns="columns" 

//     ---for checkbox---
//     :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs"


//     ---for reset filter---
//     isResetFilterEvent - boolean
// component:
//     data: source, 
//         colums(nama-require;  filters,class,colspan-no require) - on this field table create TH, 
//         sourse - on this field and template 'v-slot="slotData" ' table create TD
//         calculatedFields
//         sortFieldsForDropdown - props for sortBy dropdopdown
//     computed: sourceData - if table get data from another place

// for inputs work in TD insert this in input checkbox in table body 
//     :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"

export const mixTables = {
    data() {
        return {
            sortCol: null,
            rows: [],
            source: null,
            rowsFilters: {
                search: '',
            },
            checkedInputs:{},
			isCheckedAll:false,
            highestToLowest: [],
        }
    },


    computed: {
        rawSource() {
            if (!this.source) return [];            
            let tableSourseArray=this.sourceData || this.$store.state[this.source] || []
            let tableSourse=tableSourseArray.map(r=>({...r}))
            if(tableSourse.length==0){
                return []
            }
            if(this.calculatedFields && tableSourse.length>0){
                tableSourse.forEach(row=>{
                    for(let fieldSetting of this.calculatedFields){
                        let field={...row}
                        for(let pathPart of fieldSetting.path){
                            field=field[pathPart]
                        }
                        if(field){
                            field=fieldSetting.calcFunction(field)
                            row[fieldSetting.filter]=field
                        }else{
                            row[fieldSetting.filter]=null
                        }
                    }
                })
            }
            this.checkedInputs={}
            this.isCheckedAll=false
            let filteredTable=grandFilter(tableSourse , this.rowsFilters, this.searchField) || []

            if(this.sortCol){
                filteredTable.sort((a,b)=>{
                    //number sort
                    let _a = a;
                    let _b = b;
                    if(a.device_data && b.device_data) {
                        _a = a.device_data
                        _b = b.device_data
                    }
                    if(typeof _a[this.sortCol]==="number"){      
                        return this.highestToLowest.includes(this.sortCol) ? _b[this.sortCol] - _a[this.sortCol] : _a[this.sortCol] - _b[this.sortCol];
                    }
                    //date sort
                    if( (typeof _a[this.sortCol]==="string"&&_a[this.sortCol].match(/^\d{2}\.\d{2}\.\d{4}$/))&&(typeof _b[this.sortCol]==="string"&&_b[this.sortCol].match(/^\d{2}\.\d{2}\.\d{4}$/)) ){                        
                        let date1Arr=_a[this.sortCol].split(".")
                        let date2Arr=_b[this.sortCol].split(".")
                        let date1=new Date(date1Arr[2],date1Arr[1],date1Arr[0])
                        let date2=new Date(date2Arr[2],date2Arr[1],date2Arr[0])
                        return date1.getTime()-date2.getTime()
                    }

                    let arg1=_a[this.sortCol]
                    let arg2=_b[this.sortCol]
                    if(typeof arg1==="string"){
                        arg1=arg1.trim()
                    }
                    if(typeof arg2==="string"){
                        arg2=arg2.trim()
                    }
                    //sort void value string-null
                    if(typeof _a[this.sortCol]==="string" && (_b[this.sortCol]===null||_b[this.sortCol]==='')){       
                        return -1
                    }
                    if(typeof _b[this.sortCol]==="string" && (_a[this.sortCol]===null||_a[this.sortCol]==='')){       
                        return 1
                    }
                 

                    if(typeof arg1=='string' && typeof arg2=="string"){
                        if(_a[this.sortCol].toLowerCase()>_b[this.sortCol].toLowerCase()){
                            return 1
                        }else{
                            return -1
                        }
                    }
                    
                    return 1 
                })
                
            }
            return filteredTable
        },
        filteredRows: {
            get() {
                return this.rows
            },

            set(val) {
                this.rows = val
            }
        },
     
    
        
       
        groupOptions() {
            return [{ id: 'all', name: 'Group' }]
        },
      
    
        warrantyOptions() {
            return [
                { id: 'all', name: 'Warranty' },
                { id: 'valid', name: 'Valid' },
                { id: 'error', name: 'Error' }
            ]
        },
    },

    async created() {
        if (!this.source ) return
        await this.$store.dispatch(`GET_${this.source.toUpperCase()}`);
        this.initTables();
    },

    methods: {
        initTables() {
            this.rows = [...this.rawSource]
        },
        resetFilters(){
            this.rowsFilters={
                search: '',
            }
        },
        toggleAllCheckedInputs(){
            this.rawSource.forEach(r=>{
                this.checkedInputs[r.id] = !this.isCheckedAll
            })
            this.isCheckedAll=!this.isCheckedAll
        },
        setCheckedInputs(ev,id){
            let  value=ev.target.checked
            this.checkedInputs[id]=value
            if(!value){
                this.isCheckedAll=false
            }else{
                let b=true
                this.rawSource.forEach(r=>{
                    b = b && this.checkedInputs[r.id] 
                })
                if(b){
                    this.isCheckedAll=true
                }
            }
        },
        getCheckedInputs(){
            let result= Object.entries(this.checkedInputs).filter(i=>{
                return i[1]
            }).map(i=>{
                return i[0]
            })
            return result
        }
    }
}


export const mixTabs = {
    data() {
        return {
        }
    },
    computed: {
        activeTab: {
            get() {
                return this.$route.query.tab || this.pageTabs[0];
            },
            set(tab) {

                if(!this.isActiveTab(tab)){
                    let prevQuery=this.$route.query
                    this.$router.push({ query: { ...prevQuery, tab } });
                }
            },
        },
        isActiveTab() {
            return (tab) => (tab === this.activeTab ? "active" : "");
        },
    },
    methods: {
        switchTab(tab) {
            this.activeTab = tab;
        },
        swipeTab(direction){
            let tabs=this.pageTabs
            let activeTabIndex=tabs.indexOf(this.activeTab)
            if(direction>0){
                if(activeTabIndex<tabs.length-1){
                    this.switchTab(tabs[activeTabIndex+1])
                }
            }else{
                if(activeTabIndex>0){
                    this.switchTab(tabs[activeTabIndex-1])
                }
            }
        },
     
    }
}


// работает через попап
// <ConfirmAction 
//     v-if="deletePopupInfo.show" 
//     title="Delete Product?" 
//     message="Do you confirm deleting this?" 
//     @close="confirmDeleteClose" 
//     @confirm="confirmDeleteAction" 
//     :processing="dProcessing"
// />
//deleteQueryType-запрос на удаление
//deleteEntryName-поле, которому ставим значение NULL если запроса на удаление не существует и мы реализовываем его через запрос update
export const mixDeleteItem = {
    data() {
        return {
            deletePopupInfo: {
                id: null,
                show: false,
                name:null
            },
            dProcessing:false
        }
    },
    computed: {

    },
    methods: {
        async confirmDelete(id,deleteTitle,name){

            const options={title:'',size:'sm',okLable:"Confirm",cancelLabel:"Cancel"} 
            await this.$dialogs.confirm(deleteTitle, options).then(async res=>{
                if(res.ok){
                    let configDeleteInput={}
                    if(this.deleteEntryName){
                        configDeleteInput[this.deleteEntryName]=null
                    }
                    
                    if(this.additionalDeleteActions){
                        await this.additionalDeleteActions(id)
                    }  
                    if(this.deleteQueryType){
                        await this.deleteQuery(this.deleteQueryType,id,configDeleteInput)
                    }
                    this.refreshData()
                }
            })
        },
        confirmDeleteOpen(id,name) {
            this.deletePopupInfo.id = id;
            this.deletePopupInfo.show = true;
            this.deletePopupInfo.name=name;
        },

        confirmDeleteClose() {
            this.deletePopupInfo.id = null;
            this.deletePopupInfo.show = false;
            this.deletePopupInfo.name=null;
        },
        getDeleteRelaionId(objects,firstName,firstID,secondName,secondID){
            let id = objects.find(o=>{
                return o[firstName]==firstID && o[secondName]==secondID
            })?.id
            if(id){
                return id
            }else{
                throw "Relation id not found"; 
            }
        },
        async confirmDeleteAction() {
            this.dProcessing=true
            let configDeleteInput={}
            if(this.deleteEntryName){
                configDeleteInput[this.deleteEntryName]=null
            }
            if(this.additionalDeleteActions){
                await this.additionalDeleteActions()
            }
            await this.deleteQuery(this.deleteQueryType, this.deletePopupInfo?.id, configDeleteInput)      

            await this.refreshData();
            this.dProcessing=false
            this.confirmDeleteClose();
            if(this.deleteQueryPostRoute){
                this.$router.push(this.deleteQueryPostRoute)
            }
        },
        async deleteQuery(deleteQueryType,id,configDeleteInput={}){
            console.log('deleteQuery',deleteQueryType,id);

            try {
                await API.graphql({
                    query: graphqlMutations[deleteQueryType],
                    variables: {
                        input: {
                            id: id,
                            ...configDeleteInput
                        },
                    },
                });
            } catch (error) {
                console.log('error catch deleteQuery');
                console.error(error);
            }
        },
        // async deleteQuery(id){
        //     try {
        //         API.graphql({
        //             query: deleteCompanyDispatch,
        //             variables: {
        //                 input: {
        //                     id: id,
        //                 },
        //             },
        //         });
        //     } catch (error) {
        //         console.error(error);
        //     }
        // },
        
        async refreshData() {
            await this.$store.dispatch("GET_CATEGORIES");
            await this.$store.dispatch("GET_PRODUCTS");
            await this.$store.dispatch("GET_GROUPS"); 
            await this.$store.dispatch("GET_DEVICES");
            await this.$store.dispatch("GET_RULES");
            await this.$store.dispatch("GET_DISPATCHES");
            await this.$store.dispatch("GET_CUSTOMREPORTS");
            await this.$store.dispatch("GET_DISPATCHES");

        },
    }
}







export const mixArrayFields={
    data(){
        return {
            addDevicesPopupInfo: {
                devices: null,
                show: false,
            },
            addProductsPopupInfo:{
                products:null,
                show:false
            },
            addRulesPopupInfo:{
                show:false,
                rules:null
            },
            aProcessing:false
        }
    },
    
    methods:{
        addFieldItems(fieldName,newItems){
            if(!this.arrayFields[fieldName]){
                this.setFieldItems(fieldName,newItems)
            }else{
                this.arrayFields[fieldName]=[...this.arrayFields[fieldName],...newItems]
            }
        },
        deleteFieldItem(fieldName,itemToDelete){  
            let fieldData = [...this.arrayFields[fieldName]];
            fieldData.splice(fieldData.indexOf(itemToDelete),1)
            this.arrayFields[fieldName]=[...fieldData]
        },
        setFieldItems(fieldName,items){
            if(this.arrayFields[fieldName]){
                this.arrayFields[fieldName]=items
            }
            else{
                this.$set(this.arrayFields,fieldName,items)
            }
        },
        fieldValues(fieldName,objects=null){
            let storeData;
            if(objects){
                storeData=objects
            }else{
                storeData=this.$store.state[fieldName] || []
            }
            if(this.arrayFields[fieldName]){
                return storeData.filter(o=>{                    
                    return this.arrayFields[fieldName].indexOf(o.id)>-1
                })
            }
        },
        fieldValuesFree(fieldName,objects=null){
            let storeData;
            if(objects){
                storeData=objects
            }else{
                storeData=this.$store.state[fieldName] || []
            }
            if(this.arrayFields){
                return storeData.filter(o=>{
                    return this.arrayFields[fieldName].indexOf(o.id)===-1
                })
            }
        },
        fieldArray(fieldName){
            return this.arrayFields[fieldName]
        },
        fieldKeys(fieldName){
            return this.arrayFields[fieldName]
        },
        transformFieldToId(array,idFieldName='id'){
            if(array.length){
                return array.map(o=>{
                    return o[idFieldName]
                })    
            }else{
                return []
            }
        },
        compareFieldDiff(fieldName,originalArray,idFieldName='id'){
            let toAdd=[]
            let toDelete=[]
            let tansformOriginalArray=this.transformFieldToId(originalArray,idFieldName)
            this.arrayFields[fieldName].forEach(itemId=>{
                if(tansformOriginalArray.indexOf(itemId)===-1){
                    toAdd.push(itemId)
                }
            })
            tansformOriginalArray.forEach(itemId=>{
                if(this.arrayFields[fieldName].indexOf(itemId)===-1){
                    toDelete.push(itemId)
                }
            })
            return {toAdd,toDelete}
        },
        //categories, categoriesVies for filter add items but filter not primitive, filter is object many to many
        filterFreeObjects(mainItems,filterField,itemsToFilter){
            let result=mainItems.filter(mainItem=>{
                let b=true
                mainItem[filterField].items.map(i=>{
                    return i.id
                }).forEach(i=>{
                    b=b*itemsToFilter.indexOf(i)===-1
                })
                return b
            })
            return result
        },
        findField(objects,id,field){
            let object=objects.find(o=>{
                return o.id==id
            })
            return object?object[field]:null
        },
        async refreshData() {
            await this.$store.dispatch("GET_CATEGORIES");
            await this.$store.dispatch("GET_PRODUCTS");
            await this.$store.dispatch("GET_GROUPS"); 
            await this.$store.dispatch("GET_DEVICES");
            await this.$store.dispatch("GET_RULES");
            await this.$store.dispatch("GET_DISPATCHES");
        },

        //add popup control
        addDeviceOpen(){
			this.addDevicesPopupInfo.show=true
			this.addDevicesPopupInfo.devices=this.fieldValuesFree('devices')
        },
        addDeviceClose(){
			this.addDevicesPopupInfo.show=false
			this.addDevicesPopupInfo.devices=null
        },
        addDeviceSubmit(devices){
			this.addFieldItems('devices',devices)
			this.addDeviceClose()
        },
        


        addProductsOpen(){
			this.addProductsPopupInfo.show=true
			this.addProductsPopupInfo.products=this.fieldValuesFree('products')
        },
        addProductsClose(){
			this.addProductsPopupInfo.show=false
			this.addProductsPopupInfo.products=null
        },
        addProductsSubmit(pruducts){
			this.addFieldItems('products',pruducts)
			this.addProductsClose()
        },


        addRulesOpen() {
            this.addRulesPopupInfo.rules=this.fieldValuesFree("rules")
            this.addRulesPopupInfo.show=true
        },
 
        addRulesClose() {
            this.addRulesPopupInfo.rules=null
            this.addRulesPopupInfo.show = false;
        },
        addRulesSubmit(rules){
			this.addFieldItems('rules',rules)
			this.addRulesClose()
        },
        
    }
}



// export const mixEditControl={
//     data(){
//         return{
//             backup:null,
//             editItem:null,
//         }
//     }
// }






export const mixQrCode = {
    data() {
        return {
            qrCode: {
                code: null,
                show: false,
            },
        }
    },
    computed: {

    },
    methods: {
       openQrCode(qrCode){
           this.qrCode.show=true
           this.qrCode.code=qrCode
       },
       closeQrCode(){
        this.qrCode.show=false
        this.qrCode.code=null
    }
    }
}





