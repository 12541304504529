<template>
<div class="popup_wrpr">
	<div class="popup__window" @click.self.stop="$emit('close')">
		<div class="popup__window-content">
			<div class="popup__window-header mb-30">
				<!-- <h2>{{ editTicketData ? 'Edit' : 'Raise' }} a Ticket</h2> -->
				<h2>{{ $translate('contact-us') }}</h2>
				<button type="button" class="popup__closer" @click="$emit('close')">
                    <inline-svg :src="require('@/assets/img/close-icon.svg')" class="pointer-events-none" />
				</button>
			</div>
			<div class="popup__window-body">
                <div class="form-group form-group--wrapper">
                    <div class="form-group__row">
                        <div class="form-group__col-5">
                            <div class="el-form">
                                <label class="el-form__title">{{ $translate('subject') }}</label>
                                <input type="text" class="input" placeholder="Problem subject" v-model="subject">
                            </div>
                        </div>
                        <div class="form-group__col-5">
                            <div class="el-form">
                                <label class="el-form__title">{{ $translate('category') }}</label>
                                <Dropdown
                                    :options="[
                                        'IOT devices',
                                        'Products',
                                        'Dispatch',
                                        'Farm management',
                                        'Other'
                                    ]" 
                                    @change="category = $event.target.value"
                                    :current="category"
                                    :placeholder="$translate('select-problem-category')"
                                />
                                <!-- before translate placeholder="Select problem category" -->
                            </div>
                        </div>
                    </div>
                    <div class="form-group__row">
                        <div class="form-group__col-10">
                            <div class="el-form">
                                <label class="el-form__title">{{ $translate('description') }}</label>
                                <textarea class="textarea textarea--lg" :placeholder="$translate('description') + '...'" v-model="description"></textarea>
                            </div>
                        </div>
                    </div>
                </div>
			</div>
			<div class="popup__window-actions">
				<button type="button" class="btn btn-w-shadow btn-standart-2 popup__closer" @click="$emit('close')">
					<span>{{ $translate('cancel') }}</span>
				</button>
                <div class="tooltip_alert_wrpr">
                    <span class="tooltip_alert" v-if="!filledsNotEmpty">{{ $translate('fill-in-all-the-fields') }}</span>
                    <button type="submit" class="btn btn-primary btn-standart-2" @click="create" v-if="!editTicketData" :class="{'disabled': !filledsNotEmpty}">
                        <span>{{ $translate('contact-us') }}</span>
                    </button>
                    <button type="submit" class="btn btn-primary btn-standart-2" @click="update" v-if="editTicketData" :class="{'disabled': !filledsNotEmpty}">
                        <span>{{ $translate('update-a-ticket') }}</span>
                    </button>
                </div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import Base from '@/components/base';
import { API } from "aws-amplify";
import { createSupportTickets, updateSupportTickets } from "@/graphql/mutations";

export default {
    name: "RaiseTicketPopup",
    props: ['editTicketData'],
	components: {
		...Base,
	},
    data(){
        return {
            subject: '',
            category: '',
            description: '',
        }
    },
    computed: {
		userData() {
			return this.$store.state.userData || {}
		},
        filledsNotEmpty(){
            return this.subject.length && this.category.length && this.description.length;
        }
	},
    methods: {
        async create(){
            await API.graphql({
                query: createSupportTickets,
                variables: {
                    input: {
                        date: `${ new Date().getTime() }`,
                        status: 'Open',
                        category: this.category,
                        subject: this.subject,
                        description: this.description,
                        user: this.userData.id,
                    },
                },
            });

            this.$emit('create');
        },
        async update(){
            await API.graphql({
                query: updateSupportTickets,
                variables: {
                    input: {
                        id: this.editTicketData.id,
                        status: 'Open',
                        category: this.category,
                        subject: this.subject,
                        description: this.description,
                        user: this.userData.id,
                    },
                },
            });

            this.$emit('create');
        }
    },
    created(){
        if(this.editTicketData){
            this.subject = this.editTicketData.subject;
            this.category = this.editTicketData.category;
            this.description = this.editTicketData.description;
        }
    }
}
</script>