var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup_wrpr"},[_c('div',{staticClass:"popup__window",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;$event.stopPropagation();return _vm.$emit('close')}}},[_c('div',{staticClass:"popup__window-content"},[_c('div',{staticClass:"popup__window-header mb-30"},[_c('h2',[_vm._v(_vm._s(_vm.$translate('contact-us')))]),_vm._v(" "),_c('button',{staticClass:"popup__closer",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('inline-svg',{staticClass:"pointer-events-none",attrs:{"src":require('@/assets/img/close-icon.svg')}})],1)]),_vm._v(" "),_c('div',{staticClass:"popup__window-body"},[_c('div',{staticClass:"form-group form-group--wrapper"},[_c('div',{staticClass:"form-group__row"},[_c('div',{staticClass:"form-group__col-5"},[_c('div',{staticClass:"el-form"},[_c('label',{staticClass:"el-form__title"},[_vm._v(_vm._s(_vm.$translate('subject')))]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.subject),expression:"subject"}],staticClass:"input",attrs:{"type":"text","placeholder":"Problem subject"},domProps:{"value":(_vm.subject)},on:{"input":function($event){if($event.target.composing)return;_vm.subject=$event.target.value}}})])]),_vm._v(" "),_c('div',{staticClass:"form-group__col-5"},[_c('div',{staticClass:"el-form"},[_c('label',{staticClass:"el-form__title"},[_vm._v(_vm._s(_vm.$translate('category')))]),_vm._v(" "),_c('Dropdown',{attrs:{"options":[
                                        'IOT devices',
                                        'Products',
                                        'Dispatch',
                                        'Farm management',
                                        'Other'
                                    ],"current":_vm.category,"placeholder":_vm.$translate('select-problem-category')},on:{"change":function($event){_vm.category = $event.target.value}}})],1)])]),_vm._v(" "),_c('div',{staticClass:"form-group__row"},[_c('div',{staticClass:"form-group__col-10"},[_c('div',{staticClass:"el-form"},[_c('label',{staticClass:"el-form__title"},[_vm._v(_vm._s(_vm.$translate('description')))]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"textarea textarea--lg",attrs:{"placeholder":_vm.$translate('description') + '...'},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing)return;_vm.description=$event.target.value}}})])])])])]),_vm._v(" "),_c('div',{staticClass:"popup__window-actions"},[_c('button',{staticClass:"btn btn-w-shadow btn-standart-2 popup__closer",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',[_vm._v(_vm._s(_vm.$translate('cancel')))])]),_vm._v(" "),_c('div',{staticClass:"tooltip_alert_wrpr"},[(!_vm.filledsNotEmpty)?_c('span',{staticClass:"tooltip_alert"},[_vm._v(_vm._s(_vm.$translate('fill-in-all-the-fields')))]):_vm._e(),_vm._v(" "),(!_vm.editTicketData)?_c('button',{staticClass:"btn btn-primary btn-standart-2",class:{'disabled': !_vm.filledsNotEmpty},attrs:{"type":"submit"},on:{"click":_vm.create}},[_c('span',[_vm._v(_vm._s(_vm.$translate('contact-us')))])]):_vm._e(),_vm._v(" "),(_vm.editTicketData)?_c('button',{staticClass:"btn btn-primary btn-standart-2",class:{'disabled': !_vm.filledsNotEmpty},attrs:{"type":"submit"},on:{"click":_vm.update}},[_c('span',[_vm._v(_vm._s(_vm.$translate('update-a-ticket')))])]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }